<template>
  <div class="container">
    <iframe v-if="playUrl" :src="playUrl" style="width: 100%;height: 100%; border: 0" ref="playIframe" :name="iframeTopHref"/>
    <!--登录-->
    <Login ref="loginRef"/>
    <!--支付页面-->
    <van-popup v-if="showPaySpendVisible" :show="showPaySpendVisible" class="spend-box">
      <pay-spend></pay-spend>
    </van-popup>
    <!--悬浮球-->
<!--    <div v-if="showFloat">-->
<!--      <Float></Float>-->
<!--    </div>-->
    <!--扩展页面-->
    <van-popup v-if="extendUrl" :show="Boolean(extendUrl)" class="spend-box">
      <iframe :src="extendUrl" :name="iframeTopHref" style="width: 100%;height: 100%; border: 0"/>
    </van-popup>
  </div>
</template>

<script>
import {onMounted, ref, reactive, onUnmounted} from "vue";
import Login from '../login/Login.vue'
import {Popup, Toast} from "vant";
import {gameInfo, getPlayUrl} from "@/api/game";
import {useStore} from "vuex";
import PaySpend from "@/views/pay/Spend";
import {createOrder} from "@/api/spend";
// import Float from "@/views/Float";
import {setItem} from "@/utils/storage";
import {uploadRoleInfo} from "@/api/user_play_info";
import {gameLoginCallback, gameLogoutCallback} from "@/utils/gameCallback";
import {is_android_h5} from "@/utils/devices";
import {debugMeg} from "@/utils/function";

export default {
  components: {
    PaySpend,
    [Popup.name]: Popup,
    Login,
    // Float,
  },
  setup: function () {
    const store = useStore();
    // 游戏组件
    const playIframe = ref();
    // 登陆组件
    const loginRef = ref();

    const iframeTopHref = ref(window.location.href)

    // 背景图
    const playUrl = ref('');
    const extendUrl = ref('');
    const accessType = ref(0)
    // 弹出框
    const showPaySpendVisible = ref(false)
    const showFloat = ref(store.state.token ? true : false)
    const payOrderParam = reactive({
      small_id: 0,
      extend: '',
      amount: 1,
      game_player_id: '',
      game_player_name: '',
      role_level: '',
      server_id: '',
      server_name: '',
      props_name: '',
    })

    const uploadRoleParam = reactive({
      small_id: 0,
      server_id: '',
      server_name: '',
      role_id: "",
      role_name: "",
      role_level: '',
      combat_number: "",
    })

    onMounted(() => {
      window.addEventListener('message', listenerMessage);
      window.callGameLoginCallback = callGameLoginCallback
      window.callGameLogoutCallback = callGameLogoutCallback
    });
    onUnmounted(() => {
      window.removeEventListener('message', listenerMessage);
    })

    const listenerMessage = (e) => {
      //调试日志
      debugMeg(e.data)
      if (e.data.action) {
        switch (e.data.action) {
          case "login":
            // 拉起登陆
            if(!accessType.value || !store.state.token){
              showFloat.value = false
              callLoginAction()
            }
            break;
          case 'loginCallback':
            // 登陆回调 通知CP
            showFloat.value = true;
            callGameLoginCallback(e.data.user_id, e.data.token)
            break;
          case 'pay':
            // 拉起支付
            callPay(e.data)
            break;
          case 'payCallback':
            showPaySpendVisible.value = false;
            if (e.data.status === -1) {
              Toast('取消支付');
            } else if (e.data.status === 0) {
              Toast('支付失败');
            } else {
              Toast('支付成功');
            }
            break;
          case "uploadRole":
            callUploadRole(e.data)
            break;
          case "logout":
            callLogout()
            break
          case "setFloatCallback":
            // 个人中心页面关闭悬浮球
            showFloat.value = false
            break;
          case "setAutoLoginCallback":
            // 个人中心页面设置自动登陆状态
            store.commit('setIsAutoLogin', e.data.status);
            break;
          case "openUrl":
            // 页面跳转
            extendUrl.value = e.data.url
            break;
        }
      }
    }
    // 登陆回调游戏方
    const callGameLoginCallback = (user_id, token) => {
      if(is_android_h5() && accessType.value === 1){
        //更新本地store
        store.commit("updateLoginInfo")
      }
      if(accessType.value == 1){
        getPlayUrl(user_id,token).then(({data}) => {
          playUrl.value = data.play_url
          if (is_android_h5()){
            gameLoginCallback(user_id, token, playIframe.value)
          }
        })
      }else{
        gameLoginCallback(user_id, token, playIframe.value)
      }

    }
    //拉起登录框
    const callLoginAction = () => {
      if(is_android_h5()){
        window.xiongqi_h5.StartLogin()
      }else{
        extendUrl.value = ''
        loginRef.value.callLogin()
      }
    }
    // 拉起支付
    const callPay = (data) => {
      if(is_android_h5()){
        window.xiongqi_h5.Pay(JSON.stringify(data))
      }else{
        if(showPaySpendVisible.value){
          Toast('上笔订单未处理完成');
          return false;
        }
        // payOrderParam.small_id = store.state.smallId;
        ({extend: payOrderParam.extend} = data);
        ({amount: payOrderParam.amount} = data);
        ({game_player_id: payOrderParam.game_player_id} = data);
        ({game_player_name: payOrderParam.game_player_name} = data);
        ({role_level: payOrderParam.role_level} = data);
        ({server_id: payOrderParam.server_id} = data);
        ({server_name: payOrderParam.server_name} = data);
        ({props_name: payOrderParam.props_name} = data);
        ({uid: payOrderParam.small_id} = data);
        if(payOrderParam.small_id != store.state.smallId){
          Toast('参数有误，请重启游戏');
          return false;
        }
        //创建订单
        createOrder(payOrderParam).then(res => {
          // 传递订单号并拉起支付页面
          setItem('pay_order_number', res.data.pay_order_number)
          showPaySpendVisible.value = true;
        })
      }
    }

    //上传角色
    const callUploadRole =(data) => {
      if(is_android_h5()){
        window.xiongqi_h5.UploadRole(JSON.stringify(data))
      }else{
        ({server_id: uploadRoleParam.server_id} = data);
        ({server_name: uploadRoleParam.server_name} = data);
        ({role_id: uploadRoleParam.role_id} = data);
        ({role_name: uploadRoleParam.role_name} = data);
        ({role_level: uploadRoleParam.role_level} = data);
        ({combat_number: uploadRoleParam.combat_number} = data);
        ({uid: uploadRoleParam.small_id} = data);
        if(uploadRoleParam.small_id != store.state.smallId){
          Toast('参数有误，请重启游戏');
          return false;
        }
        //上传角色
        uploadRoleInfo(uploadRoleParam).then(() => {
          console.log('角色上传成功')
        })
      }
    }

    //注销登录
    const callLogout = () =>{
      if(is_android_h5()){
        window.xiongqi_h5.Logout()
      }else{
        // 退出登陆 隐藏悬浮球 调用退出登陆回调 重新初始化
        store.commit('logout');
        callGameLogoutCallback()
      }
    }
    //注销回调游戏方
    const callGameLogoutCallback = ()=>{
      if(accessType.value == 1 && !is_android_h5()){
        showFloat.value = false
        callLoginAction()
      }else{
        showFloat.value = false
        callLoginAction()
        gameLogoutCallback(playIframe.value)
      }
    }
    // 获取游戏信息
    gameInfo().then(res => {
      ({access_type: accessType.value} = res.data);
      if(res.data.access_type == 1){
        showFloat.value = false
        callLoginAction()
      }else{
        ({login_url: playUrl.value} = res.data);
      }
    });

    return {
      playIframe,
      loginRef,
      iframeTopHref,
      playUrl,
      extendUrl,
      showPaySpendVisible,
      showFloat,
      payOrderParam,
      callGameLoginCallback,
      callGameLogoutCallback
    };
  }
}
</script>

<style>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.back {
  background-size: cover;
  height: 100%;
  width: 100%;
}

.spend-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.float-toast {
  top: 32px;
  width: 343px;
  height: 40px;
  background: rgba(29, 33, 41, 0.75);
  border-radius: 24px;
  flex-direction: row;
  min-height: unset;
  max-width: unset;
  padding: 4px;
  box-sizing: border-box;
  justify-content: flex-start;
}

.float-toast .van-toast__icon {
  font-size: 32px;
}

.float-toast .van-toast__text {
  font-weight: 400;
  color: #FFFFFF;
  font-size: 13px;
  margin-left: 10px;
  margin-top: 0px;
}
.float-toast .van-icon__image{
  border-radius: 180%;
}

</style>
