<template>
  <!--登陆窗口-->
  <van-popup v-if="showLoginVisible" :show="showLoginVisible"
             :class="!isMobileLandscape ? 'default-dialog-pane' : 'land-dialog-pane'">
    <div class="flex align-center justify-center dialog-pane-block">
      <van-tabs v-model:active="activeName" @click-tab="changTab" class="dialog-pane-container">
<!--        <van-tab title="手机登录" name="phoneRegister">-->
<!--          <phone-register :initData="initData" @loginSuccess="loginSuccess"></phone-register>-->
<!--        </van-tab>-->
        <van-tab title="账号登录" name="accountLogin">
          <account-login :initData="initData" @loginSuccess="loginSuccess"
                         @forgetPass="showPassword"></account-login>
        </van-tab>
        <van-tab title="快速注册" name="accountRegister">
          <account-register :initData="initData" :randAccount="fastCreateAccount"
                            @loginSuccess="loginSuccess"></account-register>
        </van-tab>
      </van-tabs>
    </div>
  </van-popup>
  <!--忘记密码弹窗-->
  <van-popup v-if="showPasswordVisible" :show="showPasswordVisible"
             :class="!isMobileLandscape ? 'default-dialog-pane' : 'land-dialog-pane'">
    <Password @showLogin="showLogin"/>
  </van-popup>
  <!--选择小号-->
  <van-popup v-if="showSmallListVisible" :show="showSmallListVisible"
             :class="!isMobileLandscape ? 'default-dialog-pane' : 'land-dialog-pane'">
    <SmallList @exit="showLogin" @smallLoginSuccess="smallLoginSuccess"/>
  </van-popup>
  <!--防沉迷-->
  <van-popup v-if="showAntiVisible" :show="showAntiVisible"
             :class="!isMobileLandscape ? 'default-dialog-pane' : 'land-dialog-pane'">
    <AntiAddiction :antiIsForce="antiIsForce" @closeAntiDialog="closeAntiDialog"/>
  </van-popup>
</template>

<script>
import {Tab, Tabs, Toast, Popup} from "vant";
import AccountLogin from "@/views/login/AccountLogin";
import AccountRegister from "@/views/login/AccountRegister";
// import PhoneRegister from "@/views/login/PhoneRegister";
import {ref, reactive, computed} from "vue";
import {realAuthStatus, tabsInit} from '@/api/tool'
import {getTime} from '@/utils/make'
import {useStore} from "vuex";
import "@/style/loginDialog.css"
import Password from "@/views/password/Password";
import {is_mobile, isLandscape} from "@/utils/devices";
import SmallList from "@/views/login/SmallList";
import AntiAddiction from "@/views/login/AntiAddiction";
import {loginCallback} from "@/utils/callback";

export default {
  name: "Login",
  components: {
    AntiAddiction,
    SmallList,
    Password,
    // PhoneRegister,
    AccountRegister,
    AccountLogin,
    [Popup.name]: Popup,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  setup() {
    const store = useStore();

    const isMobileLandscape = is_mobile() && isLandscape()
    const showLoginVisible = ref(false)
    const showPasswordVisible = ref(false)
    const showSmallListVisible = ref(false)
    const showAntiVisible = ref(false)

    const antiIsForce = ref(false)

    //是否自动登录
    const isAutoLogin = ref(store.state.isAutoLogin);
    // 用户token
    const userToken = computed(() => {return store.state.token})

    //初始化数据
    const initData = reactive({
      agreement_url: "",
      agreement_title: "用户协议和隐私政策",
      agreement_default: "1"
    })
    const fastCreateAccount = ref();
    //默认展示区域
    const activeName = ref('accountLogin');

    //初始化
    const init = () => {
      randomAccount()
      tabsInit().then(({data}) => {
        initData.agreement_url = data.agreement_url
        initData.agreement_title = data.agreement_title
        initData.agreement_default = data.agreement_default
      })
    }
    //随机注册名
    const randomAccount = () => {
      let createStr = 'x';
      createStr += String(getTime()).substring(String(getTime()).length - 8);
      createStr += String(Math.floor(Math.random() * (999 - 100)) + 100);
      fastCreateAccount.value = createStr;
    }

    init()

    const callLogin = () => {
      if (userToken.value && isAutoLogin.value) {
        showSmallList()
      } else {
        // 用户未登陆
        showLogin()
      }
    }
    //切换块选择
    const changTab = ({name}) => {
      randomAccount()
      activeName.value = name
    }

    // 登陆成功
    const loginSuccess = (data) => {
      store.commit('setUser', data)
      Toast("登录成功");
      showSmallList()
    }

    const smallLoginResult = reactive({
      user_id:0,
      token:0
    })
    const smallLoginSuccess = (data) => {
      ({id:smallLoginResult.user_id} = data);
      ({token:smallLoginResult.token} = data);
      closeAll()
      realAuthStatus().then(({data}) => {
        if (data.status && !data.user_age_status) {
          antiIsForce.value = data.is_force_real == 1 ? true : false
          showAnti()
        }else{
          //登录成功回调
          handleLoginCallBack()
        }
      })
    }

    const closeAll = () => {
      showLoginVisible.value = false
      showPasswordVisible.value = false
      showSmallListVisible.value = false
      showAntiVisible.value = false
    }
    const showLogin = () => {
      closeAll()
      showLoginVisible.value = true
    }

    const showPassword = () => {
      closeAll()
      showPasswordVisible.value = true
    }

    const showSmallList = () => {
      closeAll()
      showSmallListVisible.value = true
    }

    const showAnti = () => {
      closeAll()
      showAntiVisible.value = true
    }

    const closeAntiDialog = () => {
      closeAll()
      handleLoginCallBack()
    }

    const handleLoginCallBack = ()=>{
      console.log('登陆成功')
      loginCallback(smallLoginResult)
      // Toast({
      //   message: '可在浮球-个人中心内隐藏浮球哦～',
      //   position: 'top',
      //   className: 'float-toast',
      //   icon: require('@/assets/images/float_icon.png'),
      // });
    }

    return {
      isMobileLandscape,
      showLoginVisible,
      showPasswordVisible,
      showSmallListVisible,
      showAntiVisible,

      antiIsForce,

      activeName,
      initData,
      fastCreateAccount,
      changTab,
      loginSuccess,
      showLogin,
      showPassword,
      showSmallList,
      showAnti,
      callLogin,

      smallLoginSuccess,
      closeAntiDialog,

    }
  }
}
</script>

<style>
.default-dialog-pane {
  width: 343px;
  background: none;
  overflow: hidden;
}

.land-dialog-pane {
  width:240px;
  background: none;
  overflow: hidden;
}
</style>
