<template>
  <div class="dialog-pane-form">
    <!-- 弹出框 -->
    <validator-form @valid-success="onSubmit" :rules="userFormRules">
      <!--主体内容-->
      <van-cell-group class="arrow-cell-group">
        <div class="arrow-group">
          <validator-field placeholder="请填写用户名" v-model="user.username" name="username" :validator="userFormRules.username" clearable
                     :left-icon="require('@/assets/images/login/user.png')"
                     :right-icon="openArrow ? 'arrow-up' : (historyUsers ? 'arrow-down': 'arrow-up')"
                     @click-right-icon="openArrow = !openArrow"/>
          <div class="arrow-list" v-if="historyUsers.length > 0" v-show="openArrow">
            <van-cell-group>
              <van-cell v-for="(item,key) in showHistoryUsers" :key="key">
                <template #title>
                  <div class="arrow-cell-title" @click="checkUser(key)">{{ item.username }}</div>
                </template>
                <template #right-icon>
                  <van-icon :name="require('@/assets/images/login/clear.png')" class="del-user-icon"
                            @click="deleteHisUser(key)"/>
                </template>
              </van-cell>
            </van-cell-group>
          </div>
        </div>
        <validator-field class="addon-field" placeholder="密码" v-model="user.password" name="password" :validator="userFormRules.password"
                   clearable :type="switchPassType ? 'text' : 'password'"
                   :right-icon="switchPassType ? require('@/assets/images/login/eye.png') : require('@/assets/images/login/closed-eye.png')"
                   :left-icon="require('@/assets/images/login/pass.png')"
                   @click-right-icon="switchPassType = !switchPassType">
        </validator-field>
      </van-cell-group>
      <div class="cell-agree">
<!--        <van-checkbox v-model="agreeChecked">我已阅读并同意<a :href="initData.agreement_url"-->
<!--                                                       target="_blank">《{{ initData.agreement_title }}》</a>-->
<!--        </van-checkbox>-->
        <div class="input-addon" @click="forgetPass">忘记密码</div>
      </div>
      <div class="cell-button">
        <van-button round block type="primary" native-type="submit" size="small" :disabled="!agreeChecked">
          进入游戏
        </van-button>
      </div>
    </validator-form>
  </div>
</template>

<script>
import {ref, toRefs, reactive} from "vue";
import {Popup, CellGroup, Cell, Button, Checkbox, Icon, Toast} from "vant";
import {login} from "@/api/user";
import {useStore} from "vuex"
import {isUnique} from "@/utils/function";
import ValidatorField from "@/components/ValidatorField";
import ValidatorForm from "@/components/ValidatorForm";


export default {
  name: "AccountLogin",
  components: {
    [Popup.name]: Popup,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Icon.name]: Icon,
    ValidatorForm,
    ValidatorField,
  },
  props: {
    initData: {
      type: Object,
      default: () => {
        return {
          agreement_url: "",
          agreement_title: "用户协议和隐私政策",
          agreement_default: "1"
        }
      }
    }
  },
  setup(props, context) {
    //接收父组件传值
    const {initData} = toRefs(props);// eslint-disable-line no-unused-vars
    //store声明
    const store = useStore();
    const agreeChecked = ref(true);
    //密码显示与隐藏
    const switchPassType = ref(false);
    //历史用户
    const historyUsers = store.state.historyUserList;
    const showHistoryUsers = store.state.historyUserList.reverse();//反转显示
    const user = reactive({
      username: '',
      password: '',
    });
    const openArrow = ref(false);
    //表单校验规则
    const userFormRules = {
      username: {
        label: '用户名',
        rules: "required",
      },
      password: {
        label: '账号密码',
        rules: "required;password"
      }
    };


    //历史用户选择
    const checkUser = (key) => {
      user.username = showHistoryUsers[key].username
      user.password = showHistoryUsers[key].password
      openArrow.value = false
    }

    const deleteHisUser = (key) => {
      const oddHistoryUsers = showHistoryUsers.splice(key, 1);
      store.commit('setHistoryUserList', showHistoryUsers.length > 1 ? oddHistoryUsers.reverse() : []);
    }

    const forgetPass = () => {
      context.emit("forgetPass")
    }

    //表单提交
    const onSubmit = async () => {
      Toast.loading({
        message: "登录中...",
        forbidClick: true,
      });
      login(user).then((res) => {
        //没有历史用户
        if (!historyUsers) {
          const userList = [];
          userList.push(user)
          store.commit('setHistoryUserList', userList)
        } else {
          //查找重复历史用户
          const userIndexOf = isUnique(historyUsers, 'username', user.username);
          if (userIndexOf > -1) {
            historyUsers[userIndexOf] = user
          } else {
            historyUsers.push(user);
          }
          //历史用户超过5个删除数据第一个
          if (historyUsers.length > 5) {
            historyUsers.shift()
          }
          store.commit('setHistoryUserList', historyUsers)
        }
        context.emit('loginSuccess', res.data)
      })
    }

    return {
      userFormRules,
      store,
      switchPassType,
      user,
      agreeChecked,
      historyUsers,
      openArrow,
      showHistoryUsers,

      onSubmit,
      checkUser,
      forgetPass,
      deleteHisUser,
      context,
    }
  },
};
</script>

<style scoped>
.arrow-cell-group {
  overflow: unset;
}

.arrow-group {
  position: relative;
}

.arrow-list {
  position: absolute;
  top: 44px;
  width: 100%;
  z-index: 999;
  left: 0px;
  right: 0px;
  margin: auto;
  background: #FFFFFF;
}

.arrow-list .van-cell-group {
  margin-bottom: 0px;
}

.arrow-list .van-cell-group .van-cell {
  margin-top: 0px;
  display: flex;
  align-items: center;
  padding-left: 37px;
  font-size: 14px;
  color: #333333;
  height: 43px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.arrow-list .van-cell-group .van-cell::after {
  height: 2px;
  color: #DCDCDC;
}
.arrow-list .van-cell-group :deep(.van-cell__title) {
  text-align: left;
}
.del-user-icon {
  font-size: 16px;
  cursor: pointer;
}
.cell-agree {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-addon {
  font-size: 12px;
}
/*横屏*/
.land-dialog-pane .arrow-list {
  top:26px;
}
.land-dialog-pane .arrow-list .van-cell-group .van-cell {
  padding-left: 26px;
  font-size: 10px;
  height:26px;
}
.land-dialog-pane .del-user-icon {
  font-size: 11px;
}
.land-dialog-pane .input-addon {
  font-size: 8px;
}
</style>
